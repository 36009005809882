<template>
    <div>
        <div id="page-wrapper" class="m-0 w-100">
            <div class="row">
                <b-navbar toggleable="md" variant="primary" type="dark" class="w-100">
                    <b-button v-b-toggle.sidebar><font-awesome-icon icon="bars"></font-awesome-icon></b-button>

<!--                    <b-navbar-brand :to="homepage.route" class="p-0">-->
<!--                        <b-img v-if="homepage.logo !== ''" :src="homepage.logo" style="max-height: 56px;" alt="Logo"></b-img>-->
<!--                        <template v-else>{{homepage.title}}</template>-->
<!--                    </b-navbar-brand>-->

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item :href="DOCS" :active="true">
                            <font-awesome-icon icon="user-circle"></font-awesome-icon> {{ user.name }}
                            <span class="mr-2 d-none d-md-inline"></span>
                        </b-nav-item>

<!--                        <b-nav-item :href="DOCS" target="_blank" :active="true" v-b-tooltip.hover.bottom title="Ayuda">-->
<!--                            <span class="ml-2 d-none d-md-inline"></span>-->
<!--                            <font-awesome-icon icon="question-circle"></font-awesome-icon> <span class="d-inline d-md-none">Ayuda</span>-->
<!--                        </b-nav-item>-->

                        <b-nav-item @click="logOut" :active="true" v-b-tooltip.hover.bottom title="Log out">
                            <span class="ml-2 d-none d-md-inline"></span>
                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> <span class="d-inline d-md-none">Log out</span>
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>
            </div>
            <div class="row wrapper border-bottom foreground page-heading">
                <Breadcrumb :title="breadcrumb.title" :path="breadcrumb.path"></Breadcrumb>
            </div>
            <div class="container-fluid wrapper wrapper-content">
                <router-view @update-breadcrumb="updateBreadcrumb" @user-data-change="updateUserData"></router-view>
            </div>
        </div>

        <Sidebar :menu="menu"></Sidebar>
    </div>
</template>

<script>
import { logOutUrl, vehicleAvailabilityUrl } from '@routes';
import * as constants from '@constants';
import Breadcrumb from '@cooper/src/components/bootstrap/Breadcrumb.vue';
import Sidebar from '../components/sidebar';
import { datePickerLabels } from '@/i18n/datePickerI18n';
const dayjs = require('dayjs');

export default {
    data() {
        return {
            homepage: {
                logo: '',
                title: 'Inicio',
                route: { name: 'UserIndex' }
            },
            user: {
                id: 0,
                name: '',
                email: '',
                roles: ''
            },
            availableVehicles: [],
            filters: {
                date: ''
            },
            datePickerLabels: datePickerLabels.es,
            breadcrumb: {
                title: '',
                path: []
            },
            menu: [
                {
                    title: 'Menu',
                    icon: '',
                    module: 'Home',
                    route: { name: 'DashboardIndex' }
                },
                {
                    title: 'Dashboard',
                    icon: '',
                    module: 'dashboard',
                    route: { name: 'DashboardIndex' }
                },
                {
                    title: 'Production',
                    header: true,
                    children: [
                        {
                            title: 'Production lines',
                            icon: 'download',
                            module: 'production',
                            route: { name: 'ProductionLineIndex' },
                            rule: 'ROLE_PRLI_L'
                        }/*,
                        {
                            title: 'Bulk load',
                            icon: 'file',
                            module: 'production',
                            route: { name: 'BulkLoadIndex' },
                            rule: 'ROLE_BULO_L'
                        }*/
                    ]
                },
                {
                    title: 'Compartments',
                    icon: '',
                    module: 'compartment',
                    route: { name: 'CompartmentIndex' },
                    rule: 'ROLE_COMP_L'
                },
                {
                    title: 'Users',
                    icon: '',
                    module: 'users',
                    route: { name: 'UsersIndex' },
                    rule: 'ROLE_USER_L'
                },
                {
                    title: 'Catalogues',
                    header: true,
                    children: [
                        {
                            title: 'Crops',
                            icon: 'seedling',
                            module: 'crops',
                            route: { name: 'CropsIndex' },
                            rule: 'ROLE_CROP_L'
                        },
                        {
                            title: 'Parents',
                            icon: 'venus-mars',
                            module: 'parents',
                            route: { name: 'ParentsIndex' },
                            rule: 'ROLE_PARE_L'
                        },
                        {
                            title: 'Varieties',
                            icon: 'leaf',
                            module: 'varieties',
                            route: { name: 'VarietiesIndex' },
                            rule: 'ROLE_VARI_L'
                        }
                    ]
                }
            ]
        };
    },
    created() {
        this.filters.date = dayjs().format('YYYY-MM-DD');
        this.user.id = localStorage.getItem('userId');
        this.user.name = localStorage.getItem('userFullName');
    },
    components: {
        Breadcrumb,
        Sidebar
    },
    methods: {
        updateUserData(user) {
            this.user.id = user.id;
            this.user.name = user.name;
        },
        logOut() {
            document.body.classList.remove('margin-left-sidebar');
            document.body.classList.add('no-margin-sidebar');

            // TODO Esta función elimina el backend el token y posteriormente del frontend
            // this.axios.delete(logOutUrl(), {
            //     data: { clientToken: localStorage.getItem('clientToken') }
            // }).finally(() => {
            //     localStorage.removeItem('userInfo');
            //     localStorage.removeItem('userSettings');
            //     localStorage.removeItem('accessToken');
            //     localStorage.removeItem('refreshToken');
            //     localStorage.removeItem('clientId');
            //     localStorage.removeItem('clientSecret');
            //     localStorage.removeItem('clientToken');
            //
            //     this.user = {
            //         id: 0,
            //         name: '',
            //         email: '',
            //         roles: ''
            //     };
            //
            //     this.$router.replace({ name: 'Login' });
            // });
            this.$router.replace({ name: 'Login' });
        },
        updateBreadcrumb(breadcrumb) {
            this.breadcrumb = breadcrumb;
        }
    },
    computed: {
        setActiveUserMenu() {
            return this.$route.name.includes('User');
        },
        DOCS() {
            return constants.BACK_OFFICE_DOCS;
        }
    }
};
</script>

<style>
</style>